import { Button } from '@mui/material';
import { HomeConfig } from 'Config/home.config';
import { useLogin } from 'Contexts/AuthProvider/AuthProvider';
import { useEnrollForm } from 'Contexts/EnrollProvider/EnrollProvider';
import { useDialog } from 'Contexts/Modals/ModalProvider';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const Header = () => {
  const { user } = useLogin();
  const { logout } = useLogin();
  const { setStateDwolla } = useEnrollForm();
  const navigate = useNavigate();

  const logoutHandle = () => {
    setStateDwolla(0);
    logout();
  };

  const showApplyButton = false;

  const menuItems = [
    { label: 'Editor LandigPage', action: () => navigate('/backoffice-editor', { replace: true }) },
    { label: 'Request', action: () => navigate('/backoffice', { replace: true }) },

  ];
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    // aqui se configura el header color fondo
    <>
      <div className='block md:hidden' >
        <nav className=' bg-gray-900 m-0 self-stretch flex flex-col py-2.5 px-0 items-center justify-center text-left text-smi text-white font-inter lg:w-auto lg:[align-self:unset] lg:items-center lg:justify-center md:w-full md:items-center md:justify-center '>
          <div className=' flex w-full justify-between max-w-7xl'>
            <div
              className='flex flex-col items-start justify-start lg:flex-1'
              onClick={() => navigate('/')}
            >
              <img
                className='relative w-auto h-auto max-w-[100px] max-h-[100px] overflow-hidden shrink-0 cursor-pointer sm:max-w-[60px] sm:max-h-[60px] ml-6'
                alt=''
                src={HomeConfig.header.logo}
              />
            </div>

            <div className=' w-[200px] flex flex-col items-end justify-center gap-[10px] lg:w-auto  lg:items-end lg:justify-center md:items-end md:justify-center sm:w-auto sm:[align-self:unset] sm:items-center sm:justify-right sm:pr-5 sm:box-border'>
              <div className='self-stretch flex flex-row items-center justify-center gap-[10px]'>
                <div className='flex flex-row items-center justify-between gap-[11px]'>
                  {user?.token && (

                    <div className='flex'>
                      {menuItems.map((item) => (
                        <Button
                          key={item.label}
                          color="inherit"
                          onClick={item.action}
                          sx={{
                            color: 'white',
                            backgroundColor: item.label === 'Logout' ? 'error.main' : 'transparent',
                            '&:hover': {
                              backgroundColor: item.label === 'Logout' ? 'error.dark' : 'rgba(255,255,255,0.1)'
                            },

                          }}
                        >
                          {item.label}
                        </Button>
                      ))}

                      <Button
                        onClick={logoutHandle}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          color: 'white',
                          padding: '8px 16px',
                          borderRadius: '4px',
                          textTransform: 'none',
                        }}
                      >
                        <ExitToAppIcon style={{ marginRight: '1px' }} />
                        <span style={{ fontWeight: 'bold' }}>Logout</span>
                      </Button>
                    </div>

                  )}
                </div>
              </div>
              {showApplyButton && (
                <button className='cursor-pointer [border:none] py-[9.389999389648438px] pr-[23.30999755859375px] pl-6 bg-[transparent] self-stretch rounded-81xl [background:linear-gradient(135deg,_#f19126,_rgba(246,_166,_72,_0)),_#E6007E] flex flex-row items-center justify-center md:max-w-[175px]'>
                  <div className='flex-1 relative text-mini font-medium font-inter text-white text-center'>
                    Enroll Now
                  </div>
                </button>
              )}
            </div>
          </div>
        </nav>
      </div>
      {/* nav para moviles */}
      <nav className="hidden md:block relative w-full text-white bg-gray-900">
        <div className="flex items-center justify-between px-4 py-2">
          {/* Logo */}
          <div
            className="w-[60px] sm:w-[80px] cursor-pointer"
            onClick={() => navigate('/')}
          >
            <img
              src={HomeConfig.header.logo}
              alt="Logo"
              className="max-w-full h-auto"
            />
          </div>

          {/* Botón de menú móvil */}
          <div className="block">
            <button
              onClick={toggleMobileMenu}
              className="text-white"
            >
              {isMobileMenuOpen ? <svg className="w-8 h-8 bg-gray-900" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7">
                </path>
              </svg> : <svg className="w-8 h-8 bg-gray-900" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7">
                </path>
              </svg>}
            </button>
          </div>
        </div>

        {/* Menú desplegable móvil */}
        {isMobileMenuOpen && user?.token && (
          <div className="absolute top-full left-0 w-full bg-gray-900 z-50 py-4">
            <div className="flex flex-col items-center space-y-2">
              {menuItems.map((item) => (
                <Button
                  key={item.label}
                  color="inherit"
                  onClick={() => {
                    item.action();
                    toggleMobileMenu();
                  }}
                  className="text-white w-full"
                >
                  {item.label}
                </Button>
              ))}

              <Button
                onClick={() => {
                  logoutHandle();
                  toggleMobileMenu();
                }}
                startIcon={<ExitToAppIcon />}
                className="text-white w-full"
              >
                Logout
              </Button>

              {showApplyButton && (
                <button className="bg-gradient-to-r from-[#f19126] to-[#E6007E] text-white px-4 py-2 rounded-full w-[80%]">
                  Enroll Now
                </button>
              )}
            </div>
          </div>
        )}
      </nav>
    </>

  );
};
